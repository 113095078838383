import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import { BsSlashLg } from 'react-icons/bs';
import { RxButton } from "react-icons/rx";
import { FaArrowLeft } from "react-icons/fa6";
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { RiDeleteBin5Line } from "react-icons/ri";
import { addQuickReply_v1, getQuickReply_v1, editQuickReply_v1, deleteQucickReply_v1 } from "../../Apis/config/Url"
import Caller from '../../Apis/config/Caller';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
// import { useSnackbar } from 'notistack';
import { POST, GET, DELETE, PUT } from '../../Apis/config/RequestType';
import Cookies from 'js-cookie';

const Settings = () => {
  // const { enqueueSnackbar } = useSnackbar();
  const [qickRepliesOpen, setQuickRepliesOpen] = useState(true)
  const [addUpdateReplies, setAddUpdateReplies] = useState(false);
  const [shortcut, setShortcut] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedReply, setSelectedReply] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [shortMessage, setShortMessage] = useState('');
  // const [replyMessage, setReplyMessage] = useState('');
  const [replyMessage, setReplyMessage] = useState(``);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quickReply, setQuickReply] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [isEditPage, setIsEditPage] = useState(false);
  const [quickreplyId, setQuickReplyId] = useState('')

  const handleEditQuickReply = async (id) => {
    setQuickReplyId(id);
    setShowPopup(true);
    setIsEditMode(true);
    setIsEditPage(true)

    // Fetch the quick reply data by ID
    try {
      const response = await Caller(`${getQuickReply_v1}?id=${id}`, '', GET, false, false);
      setShowPopup(true)

      if (response.success == true) {
        setShortMessage(response.data[0].short_message);
        setReplyMessage(response.data[0].reply_message);


        setAddUpdateReplies(true);
        setQuickRepliesOpen(false)
        setIsEditMode(false);
        // setIsEditPage(false)
      }
    } catch (error) {
      console.error('Error fetching quick reply details:', error);
    }
  };

  const handleClose = () => setShowPopup(false);

  const handleShortcutChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9]/;

    if (value && !regex.test(value.charAt(0))) {
      console.log("Invalid input: Must start with a letter or number.");
      return;
    }

    setShortMessage(value);
  };



  const handleReplyMessageChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9]/;
    if (value && !regex.test(value[0])) {
      console.log("Invalid input: Must start with a letter.");
      return;
    }
    setReplyMessage(`${value}`);
  };

  const updateSaveButtonState = () => {
    if (shortMessage == '' || replyMessage == ``) {
      return true;
    } else {
      return false;
    }
  };

  const handleQickReplies = () => {
    setQuickRepliesOpen(true)
    setAddUpdateReplies(false)
  }

  const handleAddQuickReply = () => {
    setAddUpdateReplies(true);
    setQuickRepliesOpen(false);
    setIsEditMode(false);
    setIsEditPage(false);
  }

  const handleArrowClick = () => {
    setReplyMessage("")
    setShortMessage("")
    setQuickRepliesOpen(true)
    setAddUpdateReplies(false)
    setIsSaveEnabled(false)
    setShowPopup(false)
  }

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };


  const handleSaveQuickReply = async () => {
    setIsLoading(true);
    setIsEditPage(false);
    const formData = new FormData();
    formData.append('short_message', shortMessage.trim());
    formData.append('reply_message', replyMessage.trim());

    try {
      const response = await Caller(addQuickReply_v1, formData, 'POST', false, true);

      if (response.message === "Quick reply created successfully.") {
        enqueueSnackbar('Quick reply created successfully.', {
          variant: 'success',
          autoHideDuration: 2000,
          TransitionProps: { direction: 'left' },
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
        });
        setQuickReply((quickReply) => [response.data, ...quickReply])
        setShortMessage('');
        setReplyMessage('');
        setAddUpdateReplies(false);
        setQuickRepliesOpen(true);

      } else if (response.message === "Quick reply with this short message already exists.") {
        enqueueSnackbar("Quick reply with this short message already exists.", {
          variant: 'error',
          autoHideDuration: 2000,
          TransitionProps: { direction: 'left' },
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
        });
      }
    } catch (error) {
      enqueueSnackbar('An unexpected error occurred. Please try again.', {
        variant: 'error',
        autoHideDuration: 2000,
        TransitionProps: { direction: 'left' },
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePopup = (id) => {
    setSelectedId(id);
    setShowPopup(true);
  };

  const updateSaveQuickReply = async () => {
    setIsLoading(true);
    setIsEditPage(false);
    const formData = new FormData();
    formData.append('short_message', shortMessage.trim());
    formData.append('reply_message', replyMessage.trim());
    formData.append('id', quickreplyId)

    try {
      const response = await Caller(editQuickReply_v1, formData, 'POST', false, true);
      if (response.message === "Quick reply with this short message already exists.") {
        enqueueSnackbar('Quick reply with this short message already exists.', {
          variant: 'error',
          autoHideDuration: 2000,
          TransitionProps: { direction: 'left' },
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
        });

        setShowPopup(false);
      }

      else if (response.message === "Quick reply updated successfully.") {
        enqueueSnackbar('Quick reply updated successfully.', {
          variant: 'success',
          autoHideDuration: 2000,
          TransitionProps: { direction: 'left' },
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
        });
        setShortMessage('');
        setReplyMessage('');

        const updatedArray = quickReply.map((quickReply) => {
          if (quickReply.id === response?.data?.id) {
            return {
              ...quickReply,
              short_message: response?.data?.short_message,
              reply_message: response?.data?.reply_message
            };
          }
          return quickReply;  
        });

        setQuickReply(updatedArray);

        setAddUpdateReplies(false);
        setShowPopup(false);
        setQuickRepliesOpen(true);

      } else if (response.message === "Quick reply not found or no changes made.") {
        enqueueSnackbar("Quick reply not found or no changes made.", {
          variant: 'error',
          autoHideDuration: 2000,
          TransitionProps: { direction: 'left' },
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
        });
      }
      else if (response.success === false) {
        enqueueSnackbar("Actions not allowed for this user.", {
          variant: 'error',
          autoHideDuration: 2000,
          TransitionProps: { direction: 'left' },
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
        });
      }
    } catch (error) {
      enqueueSnackbar('An unexpected error occurred. Please try again.', {
        variant: 'error',
        autoHideDuration: 2000,
        TransitionProps: { direction: 'left' },
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      // Make an API call to delete the data by ID
      const deleteQuickReply = await Caller(`${deleteQucickReply_v1}?id=${id}`, '', 'DELETE', false, false);
      if (deleteQuickReply.success) {
        enqueueSnackbar('Quick Reply Deleted Successfully.', {
          variant: 'success',
          autoHideDuration: 2000,
          TransitionProps: { direction: "left" },
          anchorOrigin: { horizontal: "right", vertical: 'top' }
        });

        // Remove the deleted item from the quickReply state
        setQuickReply((prevQuickReply) => prevQuickReply.filter(item => item.id !== id));
        setShowPopup(false);
      } else {
        enqueueSnackbar('Quick Reply Not Deleted.', {
          variant: 'error',
          autoHideDuration: 2000,
          TransitionProps: { direction: "left" },
          anchorOrigin: { horizontal: "right", vertical: 'top' }
        });
      }
    } catch (error) {
      enqueueSnackbar('Error occurred while deleting.', {
        variant: 'error',
        autoHideDuration: 2000,
        TransitionProps: { direction: "left" },
        anchorOrigin: { horizontal: "right", vertical: 'top' }
      });
    }
  };



  useEffect(() => {
    const fetchQuickReply = async () => {
      try {
        const getQuickReply = await Caller(`${getQuickReply_v1}`, '', GET, false, false);
        setQuickReply(getQuickReply.data);

      } catch (error) {
        console.error('Error fetching template details:', error);

      }
    };

    fetchQuickReply();
  }, []);

  return (
    <Container fluid >
      <div style={{ display: 'flex', height:'calc(100vh - 58px)', border: '1px solid #E5E4E2', marginLeft: '37px', overflowY: 'hidden' }}>

        {/* <Col xs={1} style={{ border: '1px solid #E5E4E2', height: '92vh', width: '40px' , backgroundColor:'#F6F6F6'}}>
         
        </Col> */}
        {/* Sidebar */}
        <Col xs={3} style={{ padding: 0, borderRight: '1px solid #E5E4E2', width: '20%' }}>
          <div style={{userSelect:'none', height: '40px', padding: '8px 16px', borderBottom: '1px solid #E5E4E2', display: 'flex', alignItems: 'center' }}>
            <h6>Settings</h6>
          </div>
          <ListGroup variant="flush">
            {/* <ListGroup.Item action style={{ fontWeight: '550' }}>Profile</ListGroup.Item>
            <ListGroup.Item action style={{ fontWeight: '550' }}>Manage WABAs</ListGroup.Item>
            <ListGroup.Item action style={{ fontWeight: '550' }}>Roles</ListGroup.Item>
            <ListGroup.Item action style={{ fontWeight: '550' }}>Custom Contact Fields</ListGroup.Item>
            <ListGroup.Item action style={{ fontWeight: '550' }}>Manage Tags</ListGroup.Item> */}
            <ListGroup.Item action onClick={handleQickReplies} >
              <div style={{userSelect:'none', display: 'flex', flexDirection: 'row' }}>
                <div>
                  <BsSlashLg style={{ marginRight: '15px' }} />
                </div>
                <div style={{userSelect:'none', fontWeight: '550' }}>
                  Quick Replies
                </div>
              </div>
              <div style={{ padding: '5px', marginLeft: '1.5rem' }}>
                <span>
                  Manage your quick replies
                </span>
              </div>

            </ListGroup.Item>
            {/* <ListGroup.Item action style={{ fontWeight: '550' }}>Send Template Messages</ListGroup.Item> */}
          </ListGroup>
        </Col>

        {/* Main Content */}
        {/* {qickRepliesOpen && (<Col style={{ padding: 0, border: '1px solid #E5E4E2', height: '92vh' }}> */}
        {qickRepliesOpen && (<Col style={{ width: '10%', padding: 0, border: '1px solid #E5E4E2', height: '92vh' }}>
          <div style={{userSelect:'none', height: '40px', padding: '8px 16px', borderBottom: '1px solid #E5E4E2', display: 'flex', alignItems: 'center' }}>
            <h6>Quick Replies</h6>
          </div>
          
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '16px', height: 'calc(100% - 40px)', overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'thin', scrollbarColor: '#A5A5A5 transparent' }}>
            <div style={{ flex: '1 0 auto', width: '100%' }}>
              <ListGroup>
                {quickReply && quickReply.length > 0 ? (
                  quickReply.map((item) => (
                    <ListGroup.Item key={item.id}>

                      <div style={{ width: '95%', fontWeight: '500', color: '#A5A5A5', wordWrap: 'break-word' }}>
                        {item.short_message || ' '}
                      </div>


                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ width: '95%', fontWeight: '500', color: '#373737', wordWrap: 'break-word' }}>
                          {`${item.reply_message}` || ' '}
                        </div>

                        {(Cookies.get('auth_usertype') == '1') && <div style={{ border: 'none', height: '80%', borderRadius: '5px' }}>
                          <Button
                            style={{ border: 'none', background: 'transparent', padding: 0 }}
                            onClick={() => handlePopup(item.id)}
                          >
                            <RxButton style={{ fontSize: '50px', color: '#A5A5A5' }} />
                          </Button>
                        </div>}

                      </div>
                    </ListGroup.Item>
                  ))
                ) : (
                  <ListGroup.Item>
                    No quick replies available
                  </ListGroup.Item>
                )}
              </ListGroup>

              {showPopup && (
                <div
                  style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
                  onClick={handleOverlayClick}
                >
                  <div
                    style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', width: '300px', textAlign: 'center', width: '35%', height: '20%' }}
                    onClick={(e) => e.stopPropagation()}  // Prevent closing when clicking inside the popup
                  >
                    <Button
                      style={{ fontSize: '20px', border: 'none', backgroundColor: 'transparent', width: '100%', color: '#525050', fontWeight: '500' }}
                      onClick={() => handleEditQuickReply(selectedId)}
                    >
                      Edit
                    </Button>
                    <hr />
                    <Button
                      style={{ fontSize: '20px', border: 'none', backgroundColor: 'transparent', width: '100%', color: '#525050', fontWeight: '500' }}
                      onClick={() => handleDelete(selectedId)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              )}
            </div>


            {(Cookies.get('auth_usertype') == '1') && <div style={{userSelect:'none', position: 'absolute', bottom: '20px', right: '20px', display: 'flex', justifyContent: 'flex-end', marginTop: 'auto', zIndex: '9999' }}>
              <Button className="btn btn-success" onClick={handleAddQuickReply} style={{userSelect:'none'}}>Add Quick Reply</Button>
            </div>}


          </div>
        </Col>
        )}

        {addUpdateReplies && (<Col style={{ padding: 0, border: '1px solid #E5E4E2', height: '92vh' }}>
          <div style={{ height: '40px', padding: '8px 16px', borderBottom: '1px solid #E5E4E2', display: 'flex', alignItems: 'center' }}>
            <FaArrowLeft style={{ marginRight: '15px', fontSize: '20px', cursor: 'pointer' }} onClick={handleArrowClick} />
            <h6 style={{userSelect:'none', fontWeight: 'bold', margin: 0, flexGrow: 1 }}> {isEditPage ? 'Update quick reply' : 'Add quick reply'}</h6>
            
            <Button
              style={{
                marginLeft: 'auto',
                borderRadius: '5px',
                backgroundColor: updateSaveButtonState ? '#3F906D' : '#E5E4E2',
                border: 'none',
                padding: '8px 16px',
                height: '30px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: updateSaveButtonState ? '#fff' : '#8a8a8a',
              }}
              disabled={updateSaveButtonState() || isLoading}
              onClick={isEditPage ? updateSaveQuickReply : handleSaveQuickReply}
            >
              {isLoading ? <div className="spinner" /> : <span>{isEditPage ? 'Update' : 'Save'}</span>}
            </Button>

          </div>
          <div style={{ padding: '16px' }}>
            <Form>
              <Form.Group className="mb-3" controlId="shortcutInput">
                <Form.Label style={{userSelect:'none', fontSize: '18px', color: '#a6a4a4', fontWeight: '500' }}>Shortcut</Form.Label>
                <p style={{userSelect:'none', color: "#c2c2c2" }}>A word that will quickly retrieve this reply</p>
                <Form.Control
                  type="text"
                  className="form-control-lg"
                  value={shortMessage}
                  onChange={handleShortcutChange}
                  style={{
                    border: 'none',
                    borderBottom: '2px solid #ccc',
                    borderRadius: 0,
                    boxShadow: 'none',
                    fontSize: '18px'
                  }}
                  disabled={isEditPage}
                  maxLength={200}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="replyMessageTextarea">
                <Form.Label style={{userSelect:'none', fontSize: '18px', color: '#a6a4a4', fontWeight: '500' }}>Reply Message</Form.Label>
                <Form.Control as="textarea" rows={3} className="form-control-lg" value={replyMessage} onChange={handleReplyMessageChange} maxLength={500} style={{
                  border: 'none', borderBottom: '2px solid #ccc', borderRadius: 0, boxShadow: 'none', fontSize: '18px'
                }} />
              </Form.Group>
              {isEditMode && <Button style={{ padding: '10px', display: 'flex', alignItems: 'center', backgroundColor: '#c27e7e', color: '#fff', border: 'none', borderRadius: '4px', width: '100px', height: '40px' }} onClick={() => { console.log("delete") }}>
                <RiDeleteBin5Line style={{ marginRight: '8px', color: '#fff' }} />
                <span>Delete</span>
              </Button>}
            </Form>
          </div>
        </Col>
        )}

      </div>
      <SnackbarProvider style={{ marginTop: '15%' }} />
    </Container >
  );
};

export default Settings;
